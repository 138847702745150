@import "./../../styles/_brand-colors";

.box-line-spam {
  border-bottom: solid 1px $box-line;
  margin: 30px 0px 0px 0px;
}

.custom-spam-button {
  height: 40px;
  width: 240px;
}

.warning-icon {
  color: red;
  margin-top: 12px;
  margin-right: 10px;
}