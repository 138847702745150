@import "./../../styles/_brand-colors";

.header-classic {
    background: $header-background;
    border: solid 1px $line-gray;
    padding: 10px;
    border-radius: 4px 4px 0 0;
}

.header-classic-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header-classic-title {
    font-size: 18px;
    font-weight: 400;
}

.custom-button-header {
    width: 80px;
    float: right;
}

.buttons {
    margin-right: 10px;
}