@import "./../../styles/_brand-colors";

.line-box-2 {
  border-bottom: solid 1px $box-line;
  margin-top: 20px;
  margin-bottom: 20px;
}

.instructions-box {
  font-size: 14px;
  line-height: 25px;
  padding: 30px;
}