@import "./../../styles/_brand-colors";

.box-line-email {
  border-bottom: solid 1px $box-line;
  margin: 30px 0px 0px 0px;
}

.custom-button-height {
  height: 40px;
  width: 80px;
}