@import "./../../styles/_brand-colors";

.line-box {
  border-bottom: solid 1px $box-line;
  margin: 10px 0px 0px 0px;
}

.title {
  font-size: 16px;
  font-weight: 400;
}