.spam-icon {
  transform: scale(2.6);
  margin-top: 16px;
  margin-bottom: 32px;
  color: red;
}

.dialog-title-close-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spam-modal-text > p {
  margin: 0;
}

.delete-button {
  float: right;
  padding-right: 20px;
  width: 120px;
}

.cancel-button {
  width: 120px;
}

.delete-container {
  padding-right: 15px;
}

.cancel-container {
  padding-left: 15px;
}

.button-container {
  margin-top: 20px;
  padding-bottom: 20px;
}

.loading-container {
  margin-top: 20px;
  padding-bottom: 20px;
}