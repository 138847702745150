

.App-header {
  width: 70vw;
  height: 30vh;
  margin-right: 5rem;
}

.App {
  display: grid;
  grid-template-columns: 70% 30%;
}

.no-text-transform {
  text-transform: none !important;
}