@import "./../../../styles/_brand-colors";

.body-box {
    border: solid 1px $line-gray;
    padding: 20px;
    border-radius: 0 0 4px 4px;
    background: $white;
}

.form-container-body {
    background: white;
    min-height: 150px;
    padding-bottom: 20px;
    border-radius:  0px 0px 5px 5px;
    padding-top: 15px;
    border: solid 1px #DDD;
    border-top: 0px;
}

.form-container-header {
    background: #F5F5F5;
    border-radius: 5px 5px 0px 0px;
    padding: 15px 20px;
    border: solid 1px #DDD;
}

.send-button {
    background-color: $primary;
}

.marging-top {
    margin-top: 10px !important;
}

.custom-button {
    color: white!important;
    background: #337ab7!important;
    width: 100%;
    margin: 10px;
}

.button-row {
    display: table-cell;
    width: 490px;
}

.reservations-box {
    padding-left: 30px;
}

.modal-header-box {
    padding: 5px 5px;
}

.invalid-item {
    color: red;
}

.textarea-scroll {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.textarea-scroll::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.textarea-padding {
    padding: 0;
    margin-top: -12px;
    margin-bottom: -12px;
}

.container-loading {
    display: inline-block;
    float: right;
    margin-right: 20px;
    margin-top: 5px
}

.header-classic {
    padding: 25px;
}

.input-padding {
    padding-right: 10px;
    padding-left: 10px;
}

.sub-title {
    text-align: center;
    display: block;

    & svg {
        vertical-align: middle;
    }
}

.green-icon {
    & path {
        fill: green;
    }
}

.orange-icon {
    & path {
        fill: orange;
    }
}

.red-icon {
    & path {
        fill: red;
    }
}
