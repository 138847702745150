.ResizeHandleOuter {
    flex: 0 0 1.5em;
    position: relative;
    outline: none;
    --background-color: transparent;
}

.ResizeHandleOuter[data-resize-handle-active] {
    --background-color: var(--color-solid-resize-bar-handle);
}

.ResizeHandleInner {
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    background-color: #cccccc;
    transition: background-color 0.2s linear;
    display:flex;
    align-items: center;
    justify-content: center;
}
